import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const availableLocales = ["nl", "en", "zh"];

export function updateI18nLocale(i18nRoot, language) {
  localStorage.setItem("locale", language);
  i18nRoot.locale = getLocale();
}

function getLocale() {
  const navigatorLanguage = navigator.language.trim().split(/[-_]/)[0];
  const localStorageLanguage = localStorage.getItem("locale");

  if (
    localStorage.getItem("locale") !== "" &&
    availableLocales.includes(localStorageLanguage)
  ) {
    return localStorageLanguage;
  }

  if (availableLocales.includes(navigatorLanguage)) {
    return navigatorLanguage;
  }

  return process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en";
}

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

export default new VueI18n({
  locale: getLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages()
});
