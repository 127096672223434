<template>
  <div
    v-if="showModal"
    class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50"
  >
    <div
      class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
    ></div>

    <div
      class="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto"
    >
      <div class="modal-content text-left">
        <div class="flex justify-between items-center bg-red-700 p-4">
          <p class="text-2xl font-bold text-white">
            {{ $t("measurements_modal.view_measurements") }}
          </p>
          <div class="modal-close cursor-pointer z-50" @click="onClose">
            <svg
              class="fill-current text-white"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <div v-if="loading" class="flex items-center text-blue-800 m-4">
          <i class="fas fa-circle-notch fa-spin fa-5x mr-2"></i>
          <div class="text-xl mt-4">{{ $t("measurements_modal.loading") }}</div>
        </div>

        <div v-else class="p-4">
          <table class="table-fixed measurement-table">
            <thead>
              <tr>
                <th>{{ $t("measurements_modal.table.headers.name") }}</th>
                <th>{{ $t("measurements_modal.table.headers.value") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(size, index) in sizes" :key="index">
                <td>{{ size.name }}</td>
                <td>
                  {{
                    size.value
                      .toFixed(1)
                      .toString()
                      .replace(".", ",")
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="flex justify-end p-4">
          <button
            class="px-4 bg-transparent p-3 text-blue-800 hover:bg-gray-100 hover:text-indigo-400 mr-2"
            @click="exportCSV"
          >
            {{ $t("measurements_modal.buttons.export") }}
          </button>
          <button
            class="px-4 bg-transparent p-3 text-blue-800 hover:bg-gray-100 hover:text-indigo-400 mr-2"
            @click="onClose"
          >
            {{ $t("measurements_modal.buttons.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import exportFromJSON from "export-from-json";

export default {
  name: "MeasurementsModal",
  props: {
    loading: {
      type: Boolean
    },
    showModal: {
      type: Boolean
    },
    onClose: {
      type: Function
    },
    bodyprintId: {
      type: String
    }
  },
  data() {
    return {
      presentationOrder: [
        "chest",
        "waist",
        "band",
        "hips",
        "neck",
        "shoulders",
        "arm_length",
        "arm_length_left",
        "arm_length_right",
        "outer_leg",
        "inside_leg_long",
        "foot_length",
        "back_length",
        "upper_thigh",
        "body_length"
      ],
      filteredBodySizes: []
    };
  },
  computed: {
    ...mapGetters({ getBodyprintSizes: "bodyprint/getBodyprintSizes" }),
    sizes() {
      return this.createFilteredBodySizesList(this.getBodyprintSizes);
    }
  },
  created() {},
  methods: {
    createFilteredBodySizesList(measurements) {
      const filteredList = [];
      this.presentationOrder.forEach(element => {
        const correctSize = measurements.find(
          measurement => element === measurement.name
        );
        if (correctSize) {
          filteredList.push(correctSize);
        }
      });
      return filteredList;
    },
    exportCSV() {
      try {
        const fileName = "bodyprint sizes - " + this.bodyprintId;
        const exportType = exportFromJSON.types.csv;

        const csvData = [];
        this.getBodyprintSizes.forEach(element => {
          csvData.push({
            name: element.name,
            value: element.value.toString().replace(".", ",")
          });
        });

        exportFromJSON({ data: csvData, fileName, exportType });
      } catch (e) {
        throw new Error("Parsing failed!");
      }
    }
  },
  watch: {
    loading() {
      // Watch loading value
    }
  }
};
</script>

<style>
.measurement-table {
  height: 250px;
  overflow: scroll;
  display: block;
}

.measurement-table td {
  padding-right: 80px;
}
</style>
