<template>
  <div
    v-if="show"
    :class="barClass"
    @click="setCropActive(true)"
    :style="{
      top: barStyle.top,
      left: barStyle.left,
      width: barStyle.width,
      height: barStyle.height,
      transform: transformString,
      padding: barStyle.padding,
      marginTop: barStyle.marginTop,
      marginLeft: barStyle.marginLeft,
      position: 'absolute'
    }"
  >
    <div class="content-center w-full" v-if="isActive" :style="lineStyle"></div>
    <hr />
    <img
      :src="getImage(this.projection)"
      :style="{
        top: style.top,
        left: style.left,
        height: style.height,
        width: style.width,
        position: style.position,
        overflow: style.overflow,
        opacity: style.opacity,
        transform: style.transform
      }"
      v-if="isActive"
      @click="setCropActive(true)"
      alt="Projection image"
    />
  </div>
</template>

<script>
import interact from "interactjs";
import controlMixin from "../control-mixin";
import { mapState, mapGetters } from "vuex";

export default {
  props: {
    position: {
      type: String,
      required: true
    },
    barPosition: {
      type: String,
      required: true
    },
    length: {
      type: Number,
      required: true
    },
    startPosition: {
      type: Object,
      required: true
    },
    projection: {
      type: String
    },
    show: {
      type: Boolean
    },
    active: {
      type: Boolean
    },
    flipped: {
      type: Boolean
    }
  },
  mixins: [controlMixin],
  data() {
    return {
      bar_style: null,
      interactPosition: {
        x: this.startPosition.x,
        y: this.startPosition.y
      },
      zoom: 0,
      margin: 20,
      showBar: false
    };
  },
  computed: {
    ...mapState(["newCoordinates"]),
    ...mapGetters({
      getImage: "bodyprint/getImage",
      getPictureWidth: "bodyprint/getPictureWidth",
      getPictureHeight: "bodyprint/getPictureHeight",
      getNewCoordinates: "bodyprint/getNewCoordinates"
    }),
    isActive() {
      return this.getNewCoordinates.position === this.barPosition;
    },
    transformString() {
      const { x, y } = this.startPosition;
      if (!this.isActive) {
        if (this.position === "horizontal") {
          return `translate3D(${x - this.padding}px, ${y -
            this.length / 2 +
            14}px, 0)`;
        }
        return `translate3D(${x - this.length / 2 + 14}px, ${y -
          this.padding}px, 0)`;
      }
      if (this.position === "horizontal") {
        return `translate3D(${x - this.padding}px, 0px, 0)`;
      }
      return `translate3D(0px, ${y - this.padding}px, 0)`;
    },
    bigPictureOffset() {
      const { x, y } = this.startPosition;
      if (this.position === "horizontal") {
        return {
          x: -(x * this.zoom) + this.padding,
          y: 0
        };
      }
      return {
        x: -x,
        y: -(y * this.zoom) + this.padding
      };
    },
    barClass() {
      if (!this.isActive) {
        return `crop-bar crop-bar-${this.barPosition} flex z-20`;
      } else {
        return `crop-bar-active crop-bar-${this.barPosition} flex z-20`;
      }
    },
    padding() {
      if (this.isActive) {
        return 25;
      } else {
        return 12.5;
      }
    },
    barStyle() {
      if (!this.isActive) {
        if (this.position === "horizontal") {
          return {
            width: "",
            height: this.length - 25 + "px",
            padding: this.padding + "px"
          };
        }
        return {
          width: this.length - 25 + "px",
          height: "",
          padding: this.padding + "px"
        };
      } else {
        if (this.position === "horizontal") {
          return {
            top: 0,
            width: "",
            height: this.getPictureHeight - this.margin * 2 + "px",
            padding: this.padding + "px",
            marginTop: this.margin + "px"
          };
        }
        return {
          left: 0,
          width: this.getPictureWidth - this.margin * 2 + "px",
          height: "",
          padding: this.padding + "px",
          marginLeft: this.margin + "px"
        };
      }
    },
    lineStyle() {
      return {
        border: "1px dotted rgb(255,127,80)",
        zIndex: 100
      };
    },
    style() {
      return {
        left: this.bigPictureOffset.x + "px",
        top: this.bigPictureOffset.y + "px",
        position: "absolute",
        border: "2px solid red",
        height: this.getPictureHeight * this.zoom + "px !important",
        width: this.getPictureWidth * this.zoom + "px !important",
        overflow: "hidden",
        opacity: 1,
        transform: this.flipped ? "scaleX(-1)" : "scale(1)"
      };
    }
  },
  mounted() {
    if (this.position === "horizontal") {
      this.zoom = 1;
    } else {
      this.zoom = 2;
    }

    interact(`.crop-bar-${this.barPosition}`).draggable({
      inertia: true,
      onstart: () => {
        this.setCropActive(true);
        this.getNewCoordinates.position = this.barPosition;
      },
      onmove: event => {
        this.getNewCoordinates.position = this.barPosition;
        const x =
          this.position === "horizontal"
            ? this.interactPosition.x + event.dx
            : this.interactPosition.x;
        const y =
          this.position === "vertical"
            ? this.interactPosition.y + event.dy
            : this.interactPosition.y;
        this.interactSetPosition({ x, y });
        this.interactEndPosition();
      },
      onend: () => {
        this.interactEndPosition();
      }
    });
  },
  watch: {
    newCoordinates: {
      handler(value) {
        this.interactPosition.y = value.y;
        this.interactPosition.x = value.x;
        this.$emit("onChange", value);
      },
      deep: true
    },
    startPosition() {
      this.interactPosition = { ...this.startPosition };
    },
    show() {
      // if(this.show) {
      //   console.log(`${this.isActive} - ${this.hidden}`);
      //   if(!this.isActive) {
      //     this.setCropActive(false);
      //   }
      // }
    },
    active() {
      if (this.active) {
        this.setCropActive(false);
      }
    }
  },
  methods: {
    setCropActive(start) {
      this.setActive();
      if (start) {
        this.$emit("onStart", this.barPosition);
      }
    },
    interactSetPosition(coordinates) {
      const { x = 0, y = 0 } = coordinates;
      this.interactPosition = { x, y };
    },
    interactEndPosition() {
      const cropChange = {
        x: this.interactPosition.x,
        y: this.interactPosition.y,
        position: this.barPosition
      };
      this.$emit("onChange", cropChange);
    }
  }
};
</script>
