<template>
  <div class="flex">
    <div class="flex mx-auto p-4 w-full h-full justify-center">
      <div
        class="flex w-full h-full justify-center text-blue-800 my-12"
        style="text-align: center"
      >
        <div v-if="dataProvided">
          <i v-if="!failed" class="fas fa-circle-notch fa-spin fa-5x"></i>
          <div v-if="!failed" class="text-xl mt-4">
            {{ $t("bodyprint_queue.loading") }}
          </div>
          <div v-if="failed" class="text-xl">
            {{ $t("bodyprint_queue.loading_failed") }}
          </div>
        </div>
        <div class="w-full px-12" v-else>
          <div v-show="!loading">
            <div class="grid grid-cols-3 gap-6">
              <div
                v-if="getAuth.bodyprintQueue === true"
                :class="
                  `${
                    getAuth.bodyprintSearch || getAuth.bodyprintList
                      ? 'col-span-1'
                      : 'col-span-3'
                  } flex flex-col content-start justify-between p-4 bg-gray-200`
                "
              >
                <p class="text-xl font-bold">
                  {{ $t("bodyprint_queue.get_next_in_queue") }}
                </p>
                <div class="flex flex-col items-center justify-center">
                  <button
                    @click="getQueueItem()"
                    class="h-10 text-md px-2 m-2 bg-blue-800 text-white"
                  >
                    {{ $t("bodyprint_queue.buttons.get_queue_item") }}
                  </button>
                </div>
              </div>
              <div
                v-if="getAuth.bodyprintSearch || getAuth.bodyprintList"
                :class="
                  `${
                    getAuth.bodyprintQueue ? 'col-span-2' : 'col-span-3'
                  } content-start p-4 bg-gray-200`
                "
              >
                <p class="text-xl font-bold mb-4">
                  {{ $t("bodyprint_queue.get_bodyprints") }}
                </p>
                <div class="flex flex-col">
                  <div class="flex flex-row justify-center items-end">
                    <div class="flex flex-col items-start">
                      <label
                        for="email_address"
                        class="block font-medium text-sm text-gray-700"
                        >{{ $t("bodyprint_queue.input.email.label") }}</label
                      >
                      <div class="flex flex-row justify-center">
                        <input
                          id="email_address"
                          type="text"
                          v-model="email"
                          autocomplete="email"
                          :placeholder="
                            $t('bodyprint_queue.input.email.placeholder')
                          "
                          class="
                        mt-1
                        p-2
                        border-2 border-solid border-black
                        rounded-md
                        "
                        />
                      </div>
                    </div>
                    <div
                      v-if="getAuth.bodyprintList"
                      class="flex flex-col items-start ml-4"
                    >
                      <label
                        for="retailer_code"
                        class="block font-medium text-sm text-gray-700"
                        >{{
                          $t("bodyprint_queue.input.retailer_code.label")
                        }}</label
                      >
                      <div class="flex flex-row justify-center">
                        <input
                          id="retailer_code"
                          type="text"
                          v-model="code"
                          :placeholder="
                            $t(
                              'bodyprint_queue.input.retailer_code.placeholder'
                            )
                          "
                          class="
                        mt-1
                        p-2
                        border-2 border-solid border-black
                        rounded-md
                        "
                        />
                      </div>
                    </div>
                    <button
                      class="h-10 text-md px-3 mx-2"
                      @click="clearSearch()"
                    >
                      {{ $t("bodyprint_queue.buttons.clear") }}
                    </button>
                    <button
                      @click="searchQueue()"
                      :class="{
                        'h-10 text-md px-2 mx-2 text-white ': true,
                        'bg-blue-800': emailFilled || codeFilled,
                        'bg-gray-600': !emailFilled && !codeFilled
                      }"
                      :disabled="!emailFilled && !codeFilled"
                    >
                      {{ $t("bodyprint_queue.buttons.search") }}
                    </button>
                  </div>
                  <!--                  <p class="col-span-12 text-red-500">-->
                  <!--                    {{ this.errorMessage }}-->
                  <!--                  </p>-->
                </div>
              </div>
            </div>
            <div
              class="flex flex-row w-full justify-center"
              v-if="
                getAuth.bodyprintSearch === true ||
                  getAuth.bodyprintList === true
              "
            >
              <div class="w-full">
                <p class="text-xl font-bold mt-5">
                  {{ $t("bodyprint_queue.table.title") }}
                </p>
                <div
                  class="table-fixed w-full border-solid p-4 border-gray-200 border-2 mt-3 data-table overflow-y-auto"
                  v-if="tableLoading"
                >
                  <p class="text-lg">
                    {{ $t("bodyprint_queue.table.loading") }}
                  </p>
                </div>
                <table
                  class="table-fixed w-full border-solid px-4 border-gray-200 border-2 mt-3 data-table overflow-y-auto"
                  v-else-if="bodyprints.length > 0"
                >
                  <thead>
                    <tr>
                      <th class="w-3/12">
                        {{ $t("bodyprint_queue.table.headers.user") }}
                      </th>
                      <th class="w-2/12">
                        {{ $t("bodyprint_queue.table.headers.created_at") }}
                      </th>
                      <th class="w-2/12">
                        {{
                          $t("bodyprint_queue.table.headers.last_updated_at")
                        }}
                      </th>
                      <th class="w-3/12">
                        {{ $t("bodyprint_queue.table.headers.last_editor") }}
                      </th>
                      <th class="w-1/12">
                        {{ $t("bodyprint_queue.table.headers.retailer_code") }}
                      </th>
                      <th class="w-1/12">
                        {{ $t("bodyprint_queue.table.headers.status") }}
                      </th>
                      <th class="w-1/12">
                        {{
                          $t("bodyprint_queue.table.headers.user_permission")
                        }}
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(bodyprint, index) in this.bodyprints"
                      :key="index"
                    >
                      <td>
                        {{ bodyprint.user ? bodyprint.user.email : "-" }}
                      </td>
                      <td>{{ bodyprint.created_at }}</td>
                      <td>{{ bodyprint.last_updated_at }}</td>
                      <td>{{ bodyprint.last_editor }}</td>
                      <td>{{ bodyprint.retailer_code }}</td>
                      <td>{{ bodyprint.status }}</td>
                      <td>
                        {{ bodyprint.user ? bodyprint.user.permission : "-" }}
                      </td>
                      <td>
                        <div class="flex">
                          <button
                            @click="requestBodyprintToView(bodyprint.id)"
                            class="text-md p-2 m-2 text-white bg-blue-800"
                          >
                            {{ $t("bodyprint_queue.table.buttons.view") }}
                          </button>
                          <button
                            :disabled="bodyprint.status !== 'COMPLETED'"
                            @click="requestBodyprintSizes(bodyprint.id)"
                            :class="{
                              'text-md p-2 m-2 text-white': true,
                              'bg-blue-800': bodyprint.status === 'COMPLETED',
                              'bg-gray-600': bodyprint.status !== 'COMPLETED'
                            }"
                          >
                            {{ $t("bodyprint_queue.table.buttons.sizes") }}
                          </button>
                          <button
                            :disabled="!bodyprint.editable"
                            @click="requestBodyprint(bodyprint.id)"
                            :class="{
                              'text-md p-2 m-2 text-white': true,
                              'bg-blue-800': bodyprint.editable,
                              'bg-gray-600': !bodyprint.editable
                            }"
                          >
                            {{ $t("bodyprint_queue.table.buttons.edit") }}
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else>
                  <p v-if="tableLoading === false">
                    {{ $t("bodyprint_queue.table.no_data") }}
                  </p>
                  <i v-else class="fas fa-circle-notch fa-spin fa-5x mt-5"></i>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="!failed && loading"
            class="flex flex-col mx-auto w-full h-full items-center text-blue-800 text-2xl"
            style="text-align: center"
          >
            {{ $t("bodyprint_queue.loading") }}
          </div>
          <!-- <div v-else class="text-xl">Failed to load bodyprint</div> -->
          <div v-show="failed && !getQueueEmpty" class="flex flex-col gap-2">
            <p class="text-xl font-bold">
              {{ $t("bodyprint_queue.loading_failed") }}
            </p>
            <button @click="removeFromQueue()">
              {{ $t("bodyprint_queue.buttons.reject_bodyprint") }}
            </button>
            <button @click="backFromEmpty">
              {{ $t("bodyprint_queue.buttons.return") }}
            </button>
          </div>

          <div
            v-if="failed && getQueueEmpty"
            class="flex flex-col gap-2 text-xl"
          >
            <p>{{ $t("bodyprint_queue.queue_is_empty") }}</p>
            <button @click="backFromEmpty">
              {{ $t("bodyprint_queue.buttons.back") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <measurements-modal
      :loading="measurementsLoading"
      :showModal="showMeasurementsModal"
      :bodyprint-id="measurementsBodyprint"
      :onClose="() => (showMeasurementsModal = false)"
    />
  </div>
</template>

<script>
import MeasurementsModal from "@/components/modals/measurements-modal.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { ROUTE_BODYPRINT_CHECK, ROUTE_BODYPRINT_EDIT } from "@/router";

export default {
  name: "BodyprintQueueView",
  components: {
    MeasurementsModal
  },
  computed: {
    ...mapGetters({
      getAuthLoggedIn: "auth/getAuthLoggedIn",
      getAuth: "auth/getAuth",
      getQueueEmpty: "bodyprint/getQueueEmpty",
      getSidebarOpen: "layout/getSidebarOpen"
    }),
    ...mapActions(["auth/authCheck", "layout/toggleSidebar"])
  },
  data() {
    return {
      dataProvided: false,
      loading: false,
      tableLoading: false,
      loaded: false,
      failed: false,
      email: "",
      emailFilled: false,
      code: "",
      codeFilled: false,
      errorMessage: null,
      measurementsLoading: false,
      showMeasurementsModal: false,
      measurementsBodyprint: null,
      bodyprints: []
    };
  },
  methods: {
    backFromEmpty() {
      window.location.reload();
    },
    loadBodyPrint() {
      if (!this.$route.params.id && !this.$route.params.bodyprint_id) {
        this.dataProvided = false;
        // this.loaded = true;

        if (this.getAuth.bodyprintList === true) {
          this.getRetailerBodyprints();
        }
      } else if (
        this.$route.params.id !== false &&
        this.$route.bodyprint_id !== false
      ) {
        this.dataProvided = true;
        var user_id = this.$route.params.id;
        var bodyprint_id = this.$route.params.bodyprint_id;

        this.$store
          .dispatch("bodyprint/getBodyprint", {
            id: user_id,
            bodyprint_id: bodyprint_id
          })
          .then(() => {
            this.$store.dispatch("bodyprint/getBodyprintSizes", {
              user: user_id,
              bodyprint: bodyprint_id
            });
            this.$store.dispatch("bodyprint/checkQueue", bodyprint_id);
            this.$store
              .dispatch("bodyprint/getImages", user_id)
              .then(() => {
                this.loaded = true;
                this.$store.dispatch("bodyprint/calculateBodyprint");
              })
              .catch(() => {
                this.failed = true;
              });
          })
          .catch(() => {
            this.failed = true;
          });
      }
    },
    removeFromQueue() {
      var payload = {
        status: "REJECTED",
        rejection_reason: "Bodyprint corrupted"
      };
      this.$store.dispatch(
        "bodyprint/setQueueItemStatus",
        JSON.stringify(payload)
      );
    },
    getQueueItem() {
      this.loading = true;
      this.$store
        .dispatch("bodyprint/getFirstInQueue")
        .then(() => {
          var bp = this.$store.getters["bodyprint/getBodyprint"];
          if (bp.user) {
            this.$store.dispatch("bodyprint/getBodyprintSizes", {
              user: bp.user,
              bodyprint: bp._id
            });
            this.$store
              .dispatch("bodyprint/getImages", bp.user)
              .then(() => {
                this.loaded = true;
                this.$store.dispatch("bodyprint/calculateBodyprint");
              })
              .catch(() => {
                this.failed = true;
              });
          }
          if (this.$store.getters["bodyprint/getQueueEmpty"]) {
            this.failed = true;
          }
        })
        .catch(() => {
          this.failed = true;
        });
    },
    searchEmail() {
      this.loading = true;
      this.$store
        .dispatch("bodyprint/getBodyprintByUser", {
          email: this.email,
          code: this.code
        })
        .then(() => {
          var bp = this.$store.getters["bodyprint/getBodyPrint"];
          if (bp.user) {
            this.$store.dispatch("bodyprint/getBodyprintSizes", {
              user: bp.user,
              bodyprint: bp._id
            });
            this.$store
              .dispatch("bodyprint/getImages", bp.user)
              .then(() => {
                this.loaded = true;
                this.$store.dispatch("bodyprint/calculateBodyprint");
              })
              .catch(() => {
                this.loading = false;
                this.failed = true;
              });
          }
        })
        .catch(() => {
          this.loading = false;
          this.failed = true;
          this.errorMessage = this.$t(
            "bodyprint_queue.errors.could_not_find_bodyprint_user"
          );
        });
    },
    searchQueue() {
      if (!this.getAuth.bodyprintList && this.getAuth.bodyprintSearch) {
        if (this.email === "") {
          this.bodyprints = [];
        } else {
          this.getBodyprintsByUser(this.email, this.code);
        }
      } else if (this.getAuth.bodyprintList) {
        if (this.email === "" && this.code === "") {
          this.getRetailerBodyprints();
        } else {
          this.getBodyprintsByUser(this.email, this.code);
        }
      }
    },
    getBodyprintsByUser(email, code) {
      this.bodyprints = [];
      this.tableLoading = true;
      this.$store
        .dispatch("bodyprint/getBodyprintsByUser", {
          email: email,
          code: code
        })
        .then(() => {
          this.bodyprints = this.sortedBodyprints();
          this.tableLoading = false;
          this.errorMessage = null;
        })
        .catch(() => {
          this.tableLoading = false;
          this.errorMessage = this.$t(
            "bodyprint_queue.errors.could_not_find_bodyprint_user"
          );
        });
    },
    getRetailerBodyprints() {
      this.bodyprints = [];
      this.tableLoading = true;
      this.$store
        .dispatch("bodyprint/getBodyprintsForRetailer", {})
        .then(() => {
          this.bodyprints = this.sortedBodyprints();
          this.tableLoading = false;
          this.errorMessage = null;
        })
        .catch(() => {
          this.tableLoading = false;
          this.errorMessage = this.$t(
            "bodyprint_queue.errors.could_not_find_bodyprint_retailer"
          );
        });
    },
    requestBodyprintToView(id) {
      if (this.getSidebarOpen) {
        this.$store.dispatch("layout/toggleSidebar");
      }
      this.loading = true;
      this.$store
        .dispatch("bodyprint/requestBodyprintToView", { id: id })
        .then(() => {
          var bp = this.$store.getters["bodyprint/getBodyPrint"];
          if (bp.user) {
            this.$store.dispatch("bodyprint/getBodyprintSizes", {
              user: bp.user,
              bodyprint: bp._id
            });
            this.$store
              .dispatch("bodyprint/getImages", bp.user)
              .then(() => {
                this.$store.dispatch("bodyprint/checkBodyprint").then(() => {
                  const pushToRoute = () => {
                    this.loaded = true;
                    this.$router.push({
                      name: ROUTE_BODYPRINT_CHECK,
                      params: { queue_id: id }
                    });
                  };

                  if (this.getAuth.bodyprintSearch) {
                    this.$store
                      .dispatch("bodyprint/requestBodyprintInfo", { id: id })
                      .then(() => {
                        pushToRoute();
                      });
                  } else {
                    pushToRoute();
                  }
                });
              })
              .catch(() => {
                this.failed = true;
              });
          }
        })
        .catch(() => {
          this.loading = false;
          this.errorMessage = this.$t(
            "bodyprint_queue.errors.could_not_find_bodyprint_user"
          );
        });
    },
    requestBodyprint(id) {
      if (this.getSidebarOpen) {
        this.$store.dispatch("layout/toggleSidebar");
      }
      this.loading = true;
      this.$store
        .dispatch("bodyprint/requestBodyprint", { id: id })
        .then(() => {
          var bp = this.$store.getters["bodyprint/getBodyPrint"];
          if (bp.user) {
            this.$store.dispatch("bodyprint/getBodyprintSizes", {
              user: bp.user,
              bodyprint: bp._id
            });
            this.$store
              .dispatch("bodyprint/getImages", bp.user)
              .then(() => {
                this.$store.dispatch("bodyprint/editBodyprint").then(() => {
                  const pushToRoute = () => {
                    this.loaded = true;
                    this.$router.push({
                      name: ROUTE_BODYPRINT_EDIT,
                      params: { queue_id: id }
                    });
                  };

                  if (this.getAuth.bodyprintSearch) {
                    this.$store
                      .dispatch("bodyprint/requestBodyprintInfo", { id: id })
                      .then(() => {
                        pushToRoute();
                      });
                  } else {
                    pushToRoute();
                  }
                });
              })
              .catch(() => {
                this.failed = true;
              });
          }
        })
        .catch(() => {
          this.loading = false;
          this.errorMessage = this.$t(
            "bodyprint_queue.errors.could_not_find_bodyprint_user"
          );
        });
    },
    requestBodyprintSizes(id) {
      this.measurementsLoading = true;
      this.$store
        .dispatch("bodyprint/requestBodyprintSizes", { id: id })
        .then(() => {
          this.measurementsLoading = false;
          this.showMeasurementsModal = true;
          this.measurementsBodyprint = id;
        })
        .catch(() => {
          this.measurementsBodyprint = null;
          this.measurementsLoading = false;
          this.errorMessage = this.$t(
            "bodyprint_queue.errors.could_not_find_bodyprint_user"
          );
        });
    },
    requestBodyPrintUserSizes() {
      this.loading = true;
      this.$store
        .dispatch("bodyprint/getBodyprintByUserRaw", {
          email: this.email,
          code: this.code
        })
        .then(() => {
          this.loaded = true;
          this.loading = false;
          this.showMeasurementsModal = true;
        })
        .catch(() => {
          this.loading = false;
          this.errorMessage = this.$t(
            "bodyprint_queue.errors.could_not_find_bodyprint_user"
          );
        });
    },
    clearSearch() {
      this.email = "";
      this.code = "";
      this.searchQueue();
    },
    sortedBodyprints() {
      return this.$store.getters["bodyprint/getBodyPrints"].sort((a, b) => {
        const momentA = moment(a.last_updated_at, "DD-MM-YYYY hh:mm:ss");
        const momentB = moment(b.last_updated_at, "DD-MM-YYYY hh:mm:ss");

        if (momentA.isBefore(momentB)) {
          return 1;
        } else if (momentA.isAfter(momentB)) {
          return -1;
        } else {
          return 0;
        }
      });
    }
  },
  created() {
    if (!this.getSidebarOpen) {
      this.$store.dispatch("layout/toggleSidebar");
    }

    if (this.getAuthLoggedIn) {
      this.loadBodyPrint();
    }
  },
  watch: {
    getAuthLoggedIn() {
      if (this.getAuthLoggedIn) {
        this.loadBodyPrint();
      }
    },
    email(newEmail) {
      this.emailFilled = newEmail !== "";
    },
    code(newCode) {
      this.codeFilled = newCode !== "";
    }
  }
};
</script>
