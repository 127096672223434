<template>
  <div class="flex min-h-screen max-h-screen flex-col">
    <div v-if="!isLoading" class="flex-grow">
      <div class="flex mx-auto p-4 w-full justify-center" v-if="getBodyPrint">
        <div class="flex w-full">
          <div class="w-auto">
            <div class="border border-white">
              <bodyprint-view
                :image-source="this.getImageBack"
                :is-active="current_projection === 'back'"
                side="back"
                :flipped="this.back_image_flipped"
                :picture-height="getPictureHeight"
                :picture-width="getPictureWidth"
                :back-guide="getCropBack"
                :side-guide="getCropSide"
              />
            </div>
          </div>
          <div class="w-auto">
            <div class="border border-white">
              <bodyprint-view
                :image-source="this.getImageSide"
                :is-active="current_projection === 'left'"
                side="left"
                :flipped="side_image_flipped"
                :picture-height="getPictureHeight"
                :picture-width="getPictureWidth"
                :back-guide="getCropBack"
                :side-guide="getCropSide"
              />
            </div>
          </div>
          <div class="w-auto" v-if="current_step === 'measure'">
            <measure-set :image-flipped="imageFlippedSet()" />
          </div>
        </div>
        <div
          class="flex-grow flex flex-col justify-between bg-gray-300 -m-4 p-4"
        >
          <div class="flex-grow">
            <div class="text-xl font-semibold text-blue-800 mb-4">
              {{ $t("bodyprint_check.view_bodyprint") }}
            </div>
            <div class="my-4" v-if="getAuth.bodyprintSearch === true">
              <bodyprint-info />
            </div>
          </div>
          <div class="w-full bg-gray-200">
            <div class="flex mx-auto p-4">
              <div class="w-full grid grid-cols-2 gap-2">
                <button
                  class="col-span-1 button py-2 bg-blue-800 text-white"
                  @click="goToPreviousStep"
                >
                  {{ getPreviousStepText() }}
                </button>
                <button
                  class="col-span-1 button py-2 bg-blue-800 text-white"
                  @click="goToNextStep"
                >
                  {{ getNextStepText() }}
                </button>
                <button
                  class="col-span-2 button py-2 px-8 w-full bg-yellow-700 text-white"
                  @click="() => prepareMeasurementsModal()"
                >
                  {{ $t("bodyprint_check.buttons.view_measurements") }}
                </button>
                <button
                  class="col-span-2 button py-2 px-8 w-full bg-red-700 text-white"
                  @click="showCancelDialog = true"
                  v-show="this.isQueueItem"
                >
                  {{ $t("bodyprint_check.buttons.return") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="flex flex-col mx-auto p-4 w-full h-full items-center text-blue-800 text-2xl my-12"
      style="text-align: center"
    >
      {{ $t("bodyprint_check.loading") }}
    </div>
    <div>
      <measurements-modal
        :loading="measurementsLoading"
        :showModal="showMeasurementsModal"
        :bodyprint-id="measurementsBodyprint"
        :onClose="
          () => {
            showMeasurementsModal = false;
            measurementsBodyprint = null;
          }
        "
      />
    </div>
    <Dialog
      v-if="showCancelDialog"
      @confirmed="toEndScreen"
      @close="showCancelDialog = false"
      color="red"
      :title="$t('bodyprint_check.dialogs.return.title').toString()"
      :message="$t('bodyprint_check.dialogs.return.message').toString()"
    />
  </div>
</template>

<script>
import Dialog from "../../components/dialog.vue";
import MeasureSet from "../../components/measure/measure-set.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import MeasurementsModal from "../../components/modals/measurements-modal.vue";
import { ROUTE_BODYPRINT_QUEUE } from "@/router";
import BodyprintView from "@/components/bodyprint/bodyprint-view.vue";
import BodyprintInfo from "@/components/bodyprint/bodyprint-info.vue";

export default {
  name: "BodyprintCheckView",
  components: {
    BodyprintInfo,
    BodyprintView,
    Dialog,
    MeasureSet,
    MeasurementsModal
  },
  created() {
    if (!this.getBodyPrint) {
      this.getViewData();
      return;
    }

    this.isLoading = false;

    this.setImageWindowHeight();
    window.addEventListener("resize", this.setImageWindowHeight);
    window.addEventListener("keydown", this.handleKeydown, null);
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {});
    window.removeEventListener("keydown", this.handleKeydown);
  },
  computed: {
    ...mapGetters({
      getAuth: "auth/getAuth",
      getArea: "configuration/getArea",
      getAreas: "configuration/getAreas",
      getMeasureStep: "bodyprint/getMeasureStep",
      getSelectedMeasurePoint: "bodyprint/getSelectedMeasurePoint",
      getGuide: "bodyprint/getGuide",
      getBodyPrint: "bodyprint/getBodyPrint",
      isQueueItem: "bodyprint/isQueueItem",
      getImageSide: "bodyprint/getImageSide",
      getImageBack: "bodyprint/getImageBack",
      getPictureHeight: "bodyprint/getPictureHeight",
      getPictureWidth: "bodyprint/getPictureWidth",
      getCropBack: "bodyprint/getCropBack",
      getCropSide: "bodyprint/getCropSide"
    })
  },
  data() {
    return {
      isLoading: true,
      measurementsLoading: false,
      showSaveDialog: false,
      showCancelDialog: false,
      rejectionReason: "",
      token: "",
      showProblemModal: false,
      showMeasurementsModal: false,
      measurementsBodyprint: null,
      bodyprint: null,
      current_step: "measure",
      current_projection: "back",
      measure_step: 0,
      image_source_back: "lady_orange_back.png",
      image_source_side: "lady_orange_side.png",
      back_crop: {
        x: 0.27,
        y: 0.18,
        width: 0.47,
        height: 0.66,
        projection: "back"
      },
      side_crop: {
        x: 0.31,
        y: 0.2,
        width: 0.44,
        height: 0.6,
        projection: "left"
      },
      back_image_flipped: false,
      side_image_flipped: false
    };
  },
  watch: {},
  methods: {
    ...mapMutations({
      setActiveCoordinates: "bodyprint/setActiveCoordinates",
      setWindowHeight: "bodyprint/setWindowHeight",
      setCrop: "bodyprint/setCrop",
      setMeasureStep: "bodyprint/setMeasureStep",
      updateConstraints: "bodyprint/updateConstraints"
    }),
    ...mapActions(["auth/authLogout", "layout/toggleSidebar"]),
    getViewData() {
      const queueId = this.$route.params.queue_id;
      this.$store
        .dispatch("bodyprint/requestBodyprintToView", {
          id: queueId
        })
        .then(() => {
          var bp = this.$store.getters["bodyprint/getBodyPrint"];
          if (bp.user) {
            this.$store.dispatch("bodyprint/getBodyprintSizes", {
              user: bp.user,
              bodyprint: bp._id
            });
            this.$store
              .dispatch("bodyprint/getImages", bp.user)
              .then(() => {
                this.$store.dispatch("bodyprint/checkBodyprint").then(() => {
                  const finishGetViewData = () => {
                    this.isLoading = false;
                  };

                  if (this.getAuth.bodyprintSearch) {
                    this.$store
                      .dispatch("bodyprint/requestBodyprintInfo", {
                        id: queueId
                      })
                      .then(() => {
                        finishGetViewData();
                      });
                  } else {
                    finishGetViewData();
                  }
                });
              })
              .catch(() => {
                this.toEndScreen();
              });
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.toEndScreen();
        });
    },
    setImageWindowHeight() {
      let payload = {
        height: window.innerHeight,
        width: window.innerWidth
      };
      this.setWindowHeight(payload);
      this.setProjectionCrop(this.getBodyPrint.guides[0].crop, "back");
      this.setProjectionCrop(this.getBodyPrint.guides[1].crop, "left");
    },
    setProjectionCrop(crop, projection) {
      let updatedCrop = {
        x: crop.x,
        x2: crop.x + crop.width,
        y: crop.y,
        y2: crop.y + crop.height,
        width: crop.width,
        height: crop.height
      };
      this.setCrop([updatedCrop, projection]);
    },
    getPreviousStepText() {
      return this.$t("bodyprint_check.buttons.previous");
    },
    getNextStepText() {
      return this.$t("bodyprint_check.buttons.next");
    },
    goToPreviousStep() {
      this.setActiveCoordinates({}); //reset activeCoordinates
      this.measure_step = this.getMeasureStep - 1;
      this.setStepMeasure();
    },
    goToNextStep() {
      this.setActiveCoordinates({}); //reset activeCoordinates
      this.measure_step = this.getMeasureStep + 1;
      this.setStepMeasure();
    },
    setStepMeasure() {
      const payload = {
        areas: this.getAreas,
        step: this.measure_step,
        projection: this.current_projection
      };
      this.updateConstraints(payload);
      this.setMeasureStep(payload);
    },
    toEndScreen() {
      this.$router.replace({
        name: ROUTE_BODYPRINT_QUEUE
      });
      this.$store.dispatch("layout/toggleSidebar");
    },
    handleKeydown(e) {
      switch (e.code) {
        case "Enter":
          e.preventDefault();
          this.goToNextStep();
          break;
        case "Backspace":
          e.preventDefault();
          this.goToPreviousStep();
          break;
      }
    },
    prepareMeasurementsModal() {
      this.showMeasurementsModal = true;
      this.measurementsBodyprint = this.getBodyPrint._id;
    },
    imageFlippedSet() {
      if (this.getArea.projection === "left") {
        return this.side_image_flipped;
      } else if (this.getArea.projection === "back") {
        return this.back_image_flipped;
      }
      return true;
    }
  }
};
</script>

<style></style>
