<template>
  <div
    v-if="showModal"
    class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50"
  >
    <div
      class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
    ></div>

    <div
      class="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto"
    >
      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="modal-content text-left">
        <!--Title-->
        <div class="flex justify-between items-center bg-red-700 p-4">
          <p class="text-2xl font-bold text-white">
            {{ $t("problem_modal.header") }}
          </p>
          <div class="modal-close cursor-pointer z-50" @click="onClose">
            <svg
              class="fill-current text-white"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div class="p-4">
          <!--Body-->
          <p>{{ $t("problem_modal.description") }}</p>
          <div class="inline-block relative w-64 mt-4">
            <select
              v-model="rejectionReason"
              class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            >
              <option>User has wrong stance</option>
              <option>Camera is wrongly angled</option>
              <option>User is wearing non suitable clothes</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>

        <!--Footer-->
        <div class="flex justify-end p-4">
          <button
            class="px-4 bg-transparent p-3 text-blue-800 hover:bg-gray-100 hover:text-indigo-400 mr-2"
            @click="onClose"
          >
            {{ $t("problem_modal.buttons.cancel") }}
          </button>
          <button
            class="modal-close px-4 bg-blue-800 p-3 text-white hover:bg-indigo-400"
            @click="onSubmit(rejectionReason)"
          >
            {{ $t("problem_modal.buttons.submit") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProblemModal",
  props: {
    showModal: {
      type: Boolean
    },
    onClose: {
      type: Function
    },
    onSubmit: {
      type: Function
    }
  },
  data() {
    return {
      rejectionReason: ""
    };
  }
};
</script>
