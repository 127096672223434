var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col min-h-full w-full bg-gray-200 border-r border-black"},[_c('div',{staticClass:"h-16 min-h-14 w-full bg-blue-800 text-white px-4 py-4 flex justify-between items-center"},[(_vm.getAuthLoggedIn && _vm.getUserData !== null)?_c('div',{staticClass:"py-1 flex flex-row"},[_c('div',[_vm._v(_vm._s(_vm.$t("sidebar.logged_in_as", [_vm.getUserData.email])))])]):_vm._e()]),_c('div',{staticClass:"flex flex-col mx-4"},[(_vm.canNavigateToRoute(_vm.ROUTE_QUEUE))?_c('div',{class:[
        'flex flex-row p-4 items-center',
        _vm.getCurrentRoute === _vm.ROUTE_QUEUE ? 'font-bold' : ''
      ],on:{"click":_vm.navigateToQueue}},[_vm._v(" "+_vm._s(_vm.$t("sidebar.menu.bodyprint"))+" ")]):_vm._e(),(_vm.canNavigateToRoute(_vm.ROUTE_PRODUCTION))?_c('div',{class:[
        'flex flex-row p-4 items-center',
        _vm.getCurrentRoute === _vm.ROUTE_PRODUCTION ? 'font-bold' : ''
      ],on:{"click":_vm.navigateToProduction}},[_vm._v(" "+_vm._s(_vm.$t("sidebar.menu.production"))+" ")]):_vm._e(),_c('div',{class:[
        'flex flex-row p-4 items-center',
        _vm.getCurrentRoute === _vm.ROUTE_SETTINGS ? 'font-bold' : ''
      ],on:{"click":_vm.navigateToSettings}},[_vm._v(" "+_vm._s(_vm.$t("sidebar.menu.settings"))+" ")])]),_c('div',{staticClass:"border-t border-gray-400 mx-4"},[_c('div',{class:['flex flex-row p-4 items-center'],on:{"click":function($event){_vm.showLogoutDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t("sidebar.menu.logout"))+" ")])]),(_vm.showLogoutDialog)?_c('Dialog',{attrs:{"color":"red","title":_vm.$t('sidebar.dialogs.logout.title').toString(),"message":_vm.$t('sidebar.dialogs.logout.message').toString()},on:{"confirmed":_vm.logout,"close":function($event){_vm.showLogoutDialog = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }