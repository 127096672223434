<template>
  <div
    v-if="order"
    class="grid grid-cols-12 pt-6 pb-6 break-words"
    id="details"
  >
    <!--    First row : user & brand     -->
    <p class="col-span-2 font-bold">
      {{ $t("production_order_detail.data.labels.user") }}
    </p>
    <p class="col-span-4">{{ order.user.email }}</p>
    <p class="col-span-2 font-bold">
      {{ $t("production_order_detail.data.labels.brand") }}
    </p>
    <p class="col-span-4">{{ order.brand.name }}</p>
    <!--    Second row : retailer & product     -->
    <p class="col-span-2 font-bold">
      {{ $t("production_order_detail.data.labels.retailer") }}
    </p>
    <p class="col-span-4">{{ order.retailer.name }}</p>
    <p class="col-span-2 font-bold">
      {{ $t("production_order_detail.data.labels.product") }}
    </p>
    <p class="col-span-4">{{ order.product.name }}</p>
    <!--    Third row : order number & style     -->
    <p class="col-span-2 font-bold">
      {{ $t("production_order_detail.data.labels.order_number") }}
    </p>
    <p class="col-span-4">{{ order.order_number }}</p>
    <p class="col-span-2 font-bold">
      {{ $t("production_order_detail.data.labels.style") }}
    </p>
    <p class="col-span-4">{{ order.style.name }}</p>
    <!--    Fourth row : order date & fit     -->
    <p class="col-span-2 font-bold">
      {{ $t("production_order_detail.data.labels.order_date") }}
    </p>
    <p class="col-span-4">{{ getCreatedAtDate() }}</p>
    <p class="col-span-2 font-bold">
      {{ $t("production_order_detail.data.labels.fit") }}
    </p>
    <p class="col-span-4">
      {{
        order.style.fit_specification.specification_items
          ? order.style.fit_specification.specification_items[0]
              .size_item_serie_name
          : "-"
      }}
    </p>
    <!--    Fifth row : order status & tailoring     -->
    <p class="col-span-2 font-bold">
      {{ $t("production_order_detail.data.labels.order_status") }}
    </p>
    <p class="col-span-4">{{ order.status }}</p>
    <p class="col-span-2 font-bold">
      {{ $t("production_order_detail.data.labels.tailoring") }}
    </p>
    <p class="col-span-4">{{ order.tailoring }}</p>
    <!--    Sixth row : size (for rtw only)     -->
    <div v-if="isRtwTailoring" class="grid grid-cols-12 col-span-12">
      <div class="col-span-4" />
      <div class="col-span-2" />
      <p class="col-span-2 font-bold">
        {{ $t("production_order_detail.data.labels.size") }}
      </p>
      <p class="col-span-4">{{ order.match.size }}</p>
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";

export default {
  name: "ProductionOrderDetail",

  props: {
    order: {
      type: Object,
      required: true
    },
    isRtwTailoring: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    getCreatedAtDate() {
      if (!this.order?.created_at) return "";
      return moment(this.order.created_at, "DD-MM-YYYY hh:mm:ss").format(
        "YYYY-MM-DD"
      );
    }
  }
};
</script>

<style scoped></style>
