var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('div',{class:_vm.barClass,style:({
    top: _vm.barStyle.top,
    left: _vm.barStyle.left,
    width: _vm.barStyle.width,
    height: _vm.barStyle.height,
    transform: _vm.transformString,
    padding: _vm.barStyle.padding,
    marginTop: _vm.barStyle.marginTop,
    marginLeft: _vm.barStyle.marginLeft,
    position: 'absolute'
  }),on:{"click":function($event){return _vm.setCropActive(true)}}},[(_vm.isActive)?_c('div',{staticClass:"content-center w-full",style:(_vm.lineStyle)}):_vm._e(),_c('hr'),(_vm.isActive)?_c('img',{style:({
      top: _vm.style.top,
      left: _vm.style.left,
      height: _vm.style.height,
      width: _vm.style.width,
      position: _vm.style.position,
      overflow: _vm.style.overflow,
      opacity: _vm.style.opacity,
      transform: _vm.style.transform
    }),attrs:{"src":_vm.getImage(this.projection),"alt":"Projection image"},on:{"click":function($event){return _vm.setCropActive(true)}}}):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }