import { env } from "@/env";
import axios from "axios";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    api_url: env.VUE_APP_API_URL,
    client: axios
  },
  getters: {
    getApiUrl: state => {
      return state.api_url;
    },
    getClient: state => {
      return state.client;
    }
  },
  actions: {},
  mutations: {
    configureClient(state, payload) {
      state.client.defaults.headers.common.Authorization = `Basic ${payload.token}`;
      state.client.defaults.baseURL = `${state.api_url}/api/v2/`;
      state.client.defaults.headers.common["X-DOMAIN"] = window.location.host;

      Vue.prototype.$http = state.client;
    }
  }
};
