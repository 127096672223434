// import axios from "axios";
// import Vue from "vue";

export default {
  namespaced: true,
  state: {
    user: null
  },
  getters: {
    getUserData: state => {
      return state.user;
    }
  },
  actions: {
    getUserData({ state, rootGetters }, payload) {
      return rootGetters["network/getClient"]
        .get(`user/${payload.id}`)
        .then(response => {
          state.user = response.data;
        });
    },
    updateUser({ state, rootGetters }, payload) {
      return rootGetters["network/getClient"]
        .patch(`user/${state.user._id}`, payload)
        .then(response => {
          state.user = response.data;
        });
    }
  },
  mutations: {
    logout(state) {
      state.user = null;
    }
  }
};
