<template>
  <div>
    <div class="flex min-h-screen max-h-screen flex-col">
      <div class="flex mx-auto p-4 w-full justify-center">
        <div class="flex w-full justify-center">
          <div class="w-auto min-w-50">
            <div>
              <form
                v-if="!showForgetPassword"
                @submit.prevent="login"
                class="border bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
              >
                <h1 class="text-2xl mb-8">{{ $t("login.headers.login") }}</h1>
                <p class="text-red-400 mb-4" v-if="getAuth.noPermission">
                  {{ $t("login.errors.no_permission") }}
                </p>
                <div class="mb-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2"
                    for="username"
                  >
                    {{ $t("login.input.email.label") }}
                  </label>
                  <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="username"
                    type="email"
                    :placeholder="$t('login.input.email.placeholder')"
                    v-model="username"
                    required="required"
                  />
                </div>
                <div class="">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2"
                    for="password"
                  >
                    {{ $t("login.input.password.label") }}
                  </label>
                  <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="password"
                    type="password"
                    :placeholder="$t('login.input.password.placeholder')"
                    v-model="password"
                    required="required"
                  />
                </div>
                <div class="h-10">
                  <p class="text-red-400" v-if="getAuth.authenticationError">
                    {{ $t("login.errors.authentication") }}
                  </p>
                </div>
                <div class="flex items-center justify-between">
                  <input
                    class="bg-blue-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                    :value="$t('login.buttons.sign_in')"
                  />
                  <a
                    class="inline-block align-baseline font-bold text-sm text-blue-800 hover:text-blue-800"
                    @click.prevent="showForgetPassword = true"
                    href="#"
                  >
                    {{ $t("login.forgot_password") }}
                  </a>
                </div>
              </form>
              <div v-else>
                <form
                  @submit.prevent="forgotPassword"
                  class="border bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
                  v-if="resetPasswordStep === 0"
                >
                  <h1 class="text-2xl mb-8">
                    {{ $t("login.headers.forgot_password") }}
                  </h1>
                  <div class="mb-4">
                    <label
                      class="block text-gray-700 text-sm font-bold mb-2"
                      for="username"
                    >
                      {{ $t("login.input.email.label") }}
                    </label>
                    <input
                      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="username"
                      type="email"
                      :placeholder="$t('login.input.email.placeholder')"
                      v-model="username"
                      required="required"
                    />
                  </div>
                  <div class="flex items-center justify-between">
                    <input
                      class="bg-blue-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      type="submit"
                      :value="$t('login.buttons.request_password_reset')"
                    />
                    <a
                      class="inline-block align-baseline font-bold text-sm text-blue-800 hover:text-blue-800"
                      @click.prevent="showForgetPassword = false"
                      href="#"
                    >
                      {{ $t("login.login_instead") }}
                    </a>
                  </div>
                </form>
                <form
                  @submit.prevent="resetPassword"
                  class="border bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
                  v-if="resetPasswordStep === 1"
                >
                  <h1 class="text-2xl mb-2">
                    {{ $t("login.headers.password_reset") }}
                  </h1>
                  <p class="mb-8">
                    {{ $t("login.password_reset_description") }}
                  </p>
                  <div class="mb-4">
                    <label
                      class="block text-gray-700 text-sm font-bold mb-2"
                      for="resetcode"
                    >
                      {{ $t("login.input.reset_code.label") }}
                    </label>
                    <input
                      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="resetcode"
                      type="text"
                      :placeholder="$t('login.input.reset_code.placeholder')"
                      v-model="resetCode"
                      required="required"
                    />
                  </div>
                  <div class="mb-4">
                    <label
                      class="block text-gray-700 text-sm font-bold mb-2"
                      for="password"
                    >
                      {{ $t("login.input.new_password.label") }}
                    </label>
                    <input
                      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="password"
                      type="password"
                      :placeholder="$t('login.input.new_password.placeholder')"
                      v-model="password"
                      required="required"
                    />
                  </div>
                  <div class="flex items-center justify-between">
                    <input
                      class="bg-blue-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      type="submit"
                      :value="$t('login.buttons.send_password_reset')"
                    />
                    <a
                      class="inline-block align-baseline font-bold text-sm text-blue-800 hover:text-blue-800"
                      @click.prevent="showForgetPassword = false"
                      href="#"
                    >
                      {{ $t("login.login_instead") }}
                    </a>
                  </div>
                </form>
              </div>
              <p class="text-center text-gray-500 text-xs">
                {{ $t("login.trademark") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ROUTE_INDEX } from "@/router";
import { updateI18nLocale } from "@/i18n";
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      resetCode: "",
      showForgetPassword: false,
      resetPasswordStep: 0
    };
  },
  computed: {
    ...mapGetters({
      getAuth: "auth/getAuth",
      getAuthLoggedIn: "auth/getAuthLoggedIn",
      getUserData: "user/getUserData"
    })
  },
  methods: {
    login() {
      const value = btoa(`${this.username}:${this.password}`);
      this.$store.dispatch("auth/authenticate", value);
    },
    forgotPassword() {
      this.$store
        .dispatch("auth/requestPasswordReset", this.username)
        .then(() => {
          this.resetPasswordStep = 1;
        });
    },
    resetPassword() {
      this.$store
        .dispatch("auth/resetPassword", {
          email: this.username,
          password: this.password,
          code: this.resetCode
        })
        .then(() => {
          this.resetPasswordStep = 0;
          this.showForgetPassword = false;
          this.resetCode = "";
        });
    }
  },
  watch: {
    getAuthLoggedIn() {
      if (this.getAuthLoggedIn) {
        updateI18nLocale(this.$root.$i18n, this.getAuth.userLocale);
        this.$router.push({
          name: ROUTE_INDEX
        });
      }
    }
  }
};
</script>
