<template>
  <div>
    <div class="flex min-h-screen max-h-screen flex-col">
      <div class="flex mx-auto p-6 w-full justify-center">
        <div class="w-11/12 min-w-50">
          <div class="grid grid-cols-7 p-6">
            <p class="col-span-2 font-bold content-center">
              {{ $t("settings.items.language.label") }}
            </p>
            <div class="col-span-2 content-center">
              <select
                v-model="selectedLanguage"
                class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              >
                <option
                  v-for="language in languages"
                  :value="language.value"
                  :key="language.value"
                  >{{ language.label }}</option
                >
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updateI18nLocale } from "@/i18n";

export default {
  name: "Settings",
  data() {
    return {
      selectedLanguage: "",
      languages: [
        { label: "Nederlands", value: "nl" },
        { label: "English", value: "en" },
        { label: "中文", value: "zh" }
      ]
    };
  },
  watch: {
    selectedLanguage(_, oldValue) {
      if (oldValue !== "") {
        this.updateLanguage(this.selectedLanguage);
      }
    }
  },
  mounted() {
    this.selectedLanguage = localStorage.getItem("locale");
  },
  methods: {
    updateLanguage(language) {
      updateI18nLocale(this.$root.$i18n, language);
      this.$store.dispatch("user/updateUser", {
        locale: language.toUpperCase()
      });
    }
  }
};
</script>
