var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex flex-col mx-auto p-4 w-full h-full items-center text-blue-800 my-12",staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"w-full px-12"},[_c('div',{staticClass:"grid grid-cols-1 gap-6"},[_c('div',{staticClass:"content-start p-4 bg-gray-200"},[_c('p',{staticClass:"text-xl font-bold mb-4"},[_vm._v(" "+_vm._s(_vm.$t("production_order_list.get_production_orders"))+" ")]),_c('div',{staticClass:"w-full flex flex-row justify-center items-end"},[_c('div',{staticClass:"flex flex-col items-start"},[_c('label',{staticClass:"block font-medium text-sm text-gray-700",attrs:{"for":"email_address"}},[_vm._v(_vm._s(_vm.$t("production_order_list.input.email.label")))]),_c('div',{staticClass:"flex flex-row justify-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"mt-1 p-2 border-2 border-solid border-black rounded-md",attrs:{"id":"email_address","type":"text","autocomplete":"email","placeholder":_vm.$t('production_order_list.input.email.placeholder')},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})])]),_c('div',{staticClass:"flex flex-col items-start ml-4"},[_c('label',{staticClass:"block font-medium text-sm text-gray-700",attrs:{"for":"retailer_code"}},[_vm._v(_vm._s(_vm.$t("production_order_list.input.retailer_code.label")))]),_c('div',{staticClass:"flex flex-row justify-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code),expression:"code"}],staticClass:"mt-1 p-2 border-2 border-solid border-black rounded-md",attrs:{"id":"retailer_code","type":"text","placeholder":_vm.$t(
                      'production_order_list.input.retailer_code.placeholder'
                    )},domProps:{"value":(_vm.code)},on:{"input":function($event){if($event.target.composing)return;_vm.code=$event.target.value}}})])]),_c('div',{staticClass:"flex flex-col items-start ml-4"},[_c('label',{staticClass:"block font-medium text-sm text-gray-700",attrs:{"for":"order_number"}},[_vm._v(_vm._s(_vm.$t("production_order_list.input.order_number.label")))]),_c('div',{staticClass:"flex flex-row justify-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderNumber),expression:"orderNumber"}],staticClass:"mt-1 p-2 border-2 border-solid border-black rounded-md",attrs:{"id":"order_number","type":"text","placeholder":_vm.$t('production_order_list.input.order_number.placeholder')},domProps:{"value":(_vm.orderNumber)},on:{"input":function($event){if($event.target.composing)return;_vm.orderNumber=$event.target.value}}})])]),_c('button',{staticClass:"h-10 text-md px-3 mx-2",on:{"click":function($event){return _vm.clearSearch()}}},[_vm._v(" "+_vm._s(_vm.$t("production_order_list.buttons.clear"))+" ")]),_c('button',{class:{
                'h-10 text-md px-2 mx-2 text-white': true,
                'bg-blue-800': _vm.emailFilled || _vm.codeFilled || _vm.orderNumberFilled,
                'bg-gray-600':
                  !_vm.emailFilled && !_vm.codeFilled && !_vm.orderNumberFilled
              },attrs:{"disabled":!_vm.emailFilled && !_vm.codeFilled && !_vm.orderNumberFilled},on:{"click":function($event){return _vm.searchOrders()}}},[_vm._v(" "+_vm._s(_vm.$t("production_order_list.buttons.search_for_orders"))+" ")])])])]),_c('div',{staticClass:"flex flex-col w-full items-center"},[_c('p',{staticClass:"text-xl font-bold mt-5"},[_vm._v(" "+_vm._s(_vm.$t("production_order_list.table.title"))+" ")]),(_vm.tableLoading)?_c('div',{staticClass:"table-fixed w-full border-solid p-4 border-gray-200 border-2 mt-3 data-table overflow-y-auto"},[_c('p',{staticClass:"text-lg"},[_vm._v(" "+_vm._s(_vm.$t("production_order_list.table.loading"))+" ")])]):(_vm.orders.length > 0)?_c('table',{staticClass:"table-fixed w-full border-solid px-4 border-gray-200 border-2 mt-3 data-table overflow-y-auto"},[_c('thead',[_c('tr',[_c('th',{staticClass:"w-6/12"},[_vm._v(" "+_vm._s(_vm.$t("production_order_list.table.headers.user"))+" ")]),_c('th',{staticClass:"w-3/12"},[_vm._v(" "+_vm._s(_vm.$t("production_order_list.table.headers.retailer_code"))+" ")]),_c('th',{staticClass:"w-3/12"},[_vm._v(" "+_vm._s(_vm.$t("production_order_list.table.headers.order_number"))+" ")]),_c('th')])]),_c('tbody',_vm._l((this.orders),function(order,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(order.user ? order.user.email : "-")+" ")]),_c('td',[_vm._v(_vm._s(order.queue_item.code))]),_c('td',[_vm._v(_vm._s(order.order_number))]),_c('td',[_c('div',{staticClass:"flex"},[_c('button',{class:[
                      'w-24 text-md p-2 m-2 text-white ',
                      _vm.loading ? 'bg-gray-600' : 'bg-blue-800'
                    ],attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.requestOrderToView(order.id)}}},[_vm._v(" "+_vm._s(_vm.$t("production_order_list.table.buttons.view"))+" ")])])])])}),0)]):_c('div',[(_vm.tableLoading === false)?_c('p',[_vm._v(" "+_vm._s(_vm.$t("production_order_list.table.no_data"))+" ")]):_c('i',{staticClass:"fas fa-circle-notch fa-spin fa-5x mt-5"})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }