<template>
  <div class="flex">
    <div
      class="flex flex-col mx-auto p-4 w-full h-full items-center text-blue-800 my-12"
      style="text-align: center"
    >
      <div class="w-full px-12">
        <div class="grid grid-cols-1 gap-6">
          <div class="content-start p-4 bg-gray-200">
            <p class="text-xl font-bold mb-4">
              {{ $t("production_order_list.get_production_orders") }}
            </p>
            <div class="w-full flex flex-row justify-center items-end">
              <div class="flex flex-col items-start">
                <label
                  for="email_address"
                  class="block font-medium text-sm text-gray-700"
                  >{{ $t("production_order_list.input.email.label") }}</label
                >
                <div class="flex flex-row justify-center">
                  <input
                    id="email_address"
                    type="text"
                    v-model="email"
                    autocomplete="email"
                    :placeholder="
                      $t('production_order_list.input.email.placeholder')
                    "
                    class="
                        mt-1
                        p-2
                        border-2 border-solid border-black
                        rounded-md
                        "
                  />
                </div>
              </div>
              <div class="flex flex-col items-start ml-4">
                <label
                  for="retailer_code"
                  class="block font-medium text-sm text-gray-700"
                  >{{
                    $t("production_order_list.input.retailer_code.label")
                  }}</label
                >
                <div class="flex flex-row justify-center">
                  <input
                    id="retailer_code"
                    type="text"
                    v-model="code"
                    :placeholder="
                      $t(
                        'production_order_list.input.retailer_code.placeholder'
                      )
                    "
                    class="
                        mt-1
                        p-2
                        border-2 border-solid border-black
                        rounded-md
                        "
                  />
                </div>
              </div>
              <div class="flex flex-col items-start ml-4">
                <label
                  for="order_number"
                  class="block font-medium text-sm text-gray-700"
                  >{{
                    $t("production_order_list.input.order_number.label")
                  }}</label
                >
                <div class="flex flex-row justify-center">
                  <input
                    id="order_number"
                    type="text"
                    v-model="orderNumber"
                    :placeholder="
                      $t('production_order_list.input.order_number.placeholder')
                    "
                    class="
                        mt-1
                        p-2
                        border-2 border-solid border-black
                        rounded-md
                        "
                  />
                </div>
              </div>
              <button class="h-10 text-md px-3 mx-2" @click="clearSearch()">
                {{ $t("production_order_list.buttons.clear") }}
              </button>
              <button
                @click="searchOrders()"
                :class="{
                  'h-10 text-md px-2 mx-2 text-white': true,
                  'bg-blue-800': emailFilled || codeFilled || orderNumberFilled,
                  'bg-gray-600':
                    !emailFilled && !codeFilled && !orderNumberFilled
                }"
                :disabled="!emailFilled && !codeFilled && !orderNumberFilled"
              >
                {{ $t("production_order_list.buttons.search_for_orders") }}
              </button>
            </div>
          </div>
        </div>

        <div class="flex flex-col w-full items-center">
          <p class="text-xl font-bold mt-5">
            {{ $t("production_order_list.table.title") }}
          </p>
          <div
            class="table-fixed w-full border-solid p-4 border-gray-200 border-2 mt-3 data-table overflow-y-auto"
            v-if="tableLoading"
          >
            <p class="text-lg">
              {{ $t("production_order_list.table.loading") }}
            </p>
          </div>
          <table
            class="table-fixed w-full border-solid px-4 border-gray-200 border-2 mt-3 data-table overflow-y-auto"
            v-else-if="orders.length > 0"
          >
            <thead>
              <tr>
                <th class="w-6/12">
                  {{ $t("production_order_list.table.headers.user") }}
                </th>
                <th class="w-3/12">
                  {{ $t("production_order_list.table.headers.retailer_code") }}
                </th>
                <th class="w-3/12">
                  {{ $t("production_order_list.table.headers.order_number") }}
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr v-for="(order, index) in this.orders" :key="index">
                <td>
                  {{ order.user ? order.user.email : "-" }}
                </td>
                <td>{{ order.queue_item.code }}</td>
                <td>{{ order.order_number }}</td>
                <td>
                  <div class="flex">
                    <button
                      @click="requestOrderToView(order.id)"
                      v-bind:class="[
                        'w-24 text-md p-2 m-2 text-white ',
                        loading ? 'bg-gray-600' : 'bg-blue-800'
                      ]"
                      :disabled="loading"
                    >
                      {{ $t("production_order_list.table.buttons.view") }}
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else>
            <p v-if="tableLoading === false">
              {{ $t("production_order_list.table.no_data") }}
            </p>
            <i v-else class="fas fa-circle-notch fa-spin fa-5x mt-5"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment/moment";
import { ROUTE_PRODUCTION_ORDER_EDIT } from "@/router";

export default {
  name: "ProductionListView",
  components: {},
  computed: {
    ...mapGetters({
      getAuthLoggedIn: "auth/getAuthLoggedIn",
      getAuth: "auth/getAuth",
      getSidebarOpen: "layout/getSidebarOpen"
    }),
    ...mapActions(["auth/authCheck", "layout/toggleSidebar"])
  },
  data() {
    return {
      dataProvided: false,
      loading: false,
      tableLoading: false,
      loaded: false,
      failed: false,
      email: "",
      code: "",
      orderNumber: "",
      emailFilled: false,
      codeFilled: false,
      orderNumberFilled: false,
      errorMessage: null,
      measurementsLoading: false,
      showMeasurementsModal: false,
      measurementsBodyprint: null,
      orders: []
    };
  },
  methods: {
    searchOrders() {
      this.orders = [];
      this.tableLoading = true;

      this.$store
        .dispatch("retailerOrders/getOrdersForRetailer", {
          email: this.email,
          code: this.code,
          order_number: this.orderNumber
        })
        .then(() => {
          this.orders = this.sortedOrders();
          this.tableLoading = false;
          this.errorMessage = null;
        })
        .catch(() => {
          this.tableLoading = false;
          this.errorMessage = "Could not find orders.";
        });
    },
    sortedOrders() {
      return this.$store.getters["retailerOrders/getOrders"].sort((a, b) => {
        const momentA = moment(a.created_at, "DD-MM-YYYY hh:mm:ss");
        const momentB = moment(b.created_at, "DD-MM-YYYY hh:mm:ss");

        if (momentA.isBefore(momentB)) {
          return 1;
        } else if (momentA.isAfter(momentB)) {
          return -1;
        } else {
          return 0;
        }
      });
    },
    clearSearch() {
      this.email = "";
      this.code = "";
      this.orderNumber = "";
      this.searchOrders();
    },
    requestOrderToView(id) {
      this.loading = true;
      this.$store.dispatch("retailerOrders/requestOrderToView", id).then(() => {
        var order = this.$store.getters["retailerOrders/getOrder"];
        this.$store
          .dispatch("retailerOrders/getImages", order.id)
          .then(() => {
            this.$store.dispatch("retailerOrders/prepareOrderView").then(() => {
              this.loaded = true;
              this.loading = false;
              if (this.getSidebarOpen) {
                this.$store.dispatch("layout/toggleSidebar");
              }
              this.$router.push({
                name: ROUTE_PRODUCTION_ORDER_EDIT,
                params: { order_id: order.id }
              });
            });
          })
          .catch(() => {
            // TODO: Show error message?
            this.loading = false;
            this.failed = true;
          });
      });
    }
  },
  created() {
    this.searchOrders();
  },
  watch: {
    getAuthLoggedIn() {
      if (this.getAuthLoggedIn) {
        this.loadBodyPrint();
      }
    },
    email(newEmail) {
      this.emailFilled = newEmail !== "";
    },
    code(newCode) {
      this.codeFilled = newCode !== "";
    },
    orderNumber(newOrderNumber) {
      this.orderNumberFilled = newOrderNumber !== "";
    }
  }
};
</script>
