<template>
  <div
    class="flex flex-col min-h-full w-full bg-gray-200 border-r border-black"
  >
    <!--   Section for logged in?   -->
    <div
      class="h-16 min-h-14 w-full bg-blue-800 text-white px-4 py-4 flex justify-between items-center"
    >
      <div class="py-1 flex flex-row" v-if="getAuthLoggedIn && getUserData !== null">
        <div>{{ $t("sidebar.logged_in_as", [getUserData.email]) }}</div>
      </div>
    </div>
    <!--  Section for navigation  -->
    <div class="flex flex-col mx-4">
      <div
        v-if="canNavigateToRoute(ROUTE_QUEUE)"
        v-bind:class="[
          'flex flex-row p-4 items-center',
          getCurrentRoute === ROUTE_QUEUE ? 'font-bold' : ''
        ]"
        @click="navigateToQueue"
      >
        {{ $t("sidebar.menu.bodyprint") }}
      </div>
      <div
        v-if="canNavigateToRoute(ROUTE_PRODUCTION)"
        v-bind:class="[
          'flex flex-row p-4 items-center',
          getCurrentRoute === ROUTE_PRODUCTION ? 'font-bold' : ''
        ]"
        @click="navigateToProduction"
      >
        {{ $t("sidebar.menu.production") }}
      </div>
      <div
        v-bind:class="[
          'flex flex-row p-4 items-center',
          getCurrentRoute === ROUTE_SETTINGS ? 'font-bold' : ''
        ]"
        @click="navigateToSettings"
      >
        {{ $t("sidebar.menu.settings") }}
      </div>
    </div>
    <!--  Section for logout  -->
    <div class="border-t border-gray-400 mx-4">
      <div
        v-bind:class="['flex flex-row p-4 items-center']"
        @click="showLogoutDialog = true"
      >
        {{ $t("sidebar.menu.logout") }}
      </div>
    </div>
    <Dialog
      v-if="showLogoutDialog"
      @confirmed="logout"
      @close="showLogoutDialog = false"
      color="red"
      :title="$t('sidebar.dialogs.logout.title').toString()"
      :message="$t('sidebar.dialogs.logout.message').toString()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  ROUTE_BODYPRINT_QUEUE,
  ROUTE_LOGIN,
  ROUTE_PRODUCTION,
  ROUTE_SETTINGS
} from "@/router";
import Dialog from "@/components/dialog.vue";

export default {
  name: "Sidebar",
  components: { Dialog },
  computed: {
    ...mapGetters({
      getAuthLoggedIn: "auth/getAuthLoggedIn",
      getAuth: "auth/getAuth",
      getUserData: "user/getUserData",
      getCurrentRoute: "application/getCurrentRoute"
    }),
    ...mapActions(["auth/authCheck"])
  },
  data() {
    return {
      showLogoutDialog: false
    };
  },
  created() {
    this.ROUTE_QUEUE = ROUTE_BODYPRINT_QUEUE;
    this.ROUTE_PRODUCTION = ROUTE_PRODUCTION;
    this.ROUTE_SETTINGS = ROUTE_SETTINGS;
  },
  methods: {
    canNavigateToRoute(route) {
      switch (route) {
        case ROUTE_BODYPRINT_QUEUE:
          return (
            this.getAuth.bodyprintQueue ||
            this.getAuth.bodyprintSearch ||
            this.getAuth.bodyprintList
          );
        case ROUTE_PRODUCTION:
          return this.getAuth.productionOrders;
        default:
          return false;
      }
    },
    navigateToQueue() {
      this.$router.replace({ name: ROUTE_BODYPRINT_QUEUE });
    },
    navigateToProduction() {
      this.$router.replace({ name: ROUTE_PRODUCTION });
    },
    navigateToSettings() {
      this.$router.replace({ name: ROUTE_SETTINGS });
    },
    logout() {
      this.$store.dispatch("auth/authLogout").then(() => {
        this.showLogoutDialog = false;
        this.$store.dispatch("layout/toggleSidebar");
        this.$router.replace({ name: ROUTE_LOGIN });
      });
    }
  }
};
</script>
