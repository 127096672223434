<template>
  <div class="flex flex-col text-blue-800">
    <div v-if="!isLoading" class="grid grid-cols-12">
      <div class="flex flex-col px-6 col-span-7">
        <div class="my-4 px-4 bg-gray-200">
          <production-order-detail
            v-if="getOrder"
            :order="getOrder"
            :is-rtw-tailoring="isRtwTailoring"
          />
        </div>

        <div class="flex flex-col space-y-2 items-center" id="datatable">
          <production-order-body-table
            :adjustments="adjustments"
            :adjustments-loading="adjustmentsLoading"
            :adjustments-error="adjustmentsError"
          />
          <production-order-garment-table
            :adjustments="adjustments"
            :fit-adjustments="fitAdjustments"
            :adjustments-loading="adjustmentsLoading"
            :adjustments-error="adjustmentsError"
            :is-rtw-tailoring="isRtwTailoring"
          />
        </div>
        <div class="col-span-4 bg-gray-200 mt-4 p-4">
          <div class="flex flex-row justify-center">
            <button
              class="button py-2 px-8 w-40 bg-red-700 text-white mx-2"
              @click="showReturnDialog = true"
            >
              {{ $t("production_order_detail.buttons.return") }}
            </button>
            <button
              v-bind:class="[
                'button py-2 px-8 w-40 text-white mx-2',
                getOrder.status !== 'NEW' ? 'bg-gray-400' : 'bg-blue-800'
              ]"
              class=""
              @click="showSaveDialog = true"
              :disabled="getOrder.status !== 'NEW'"
            >
              {{ $t("production_order_detail.buttons.save") }}
            </button>
            <button
              class="button py-2 px-8 bg-yellow-700 text-white mx-2"
              @click="showPdfDialog = true"
            >
              {{ $t("production_order_detail.buttons.export") }}
            </button>
            <button
              v-bind:class="[
                'button py-2 px-8 w-40 text-white mx-2',
                getOrder.status !== 'NEW' ? 'bg-gray-400' : 'bg-blue-800'
              ]"
              @click="showFinishDialog = true"
              :disabled="getOrder.status !== 'NEW'"
            >
              {{ $t("production_order_detail.buttons.finish") }}
            </button>
          </div>
        </div>
      </div>
      <div class="flex w-full col-span-5 bg-gray-200">
        <div class="grid grid-cols-6 p-6 w-full" id="images">
          <div class="flex flex-col col-span-3 justify-center items-center">
            <p class="font-bold text-2xl pb-2">
              {{ $t("production_order_detail.back_view") }}
            </p>
            <bodyprint-view
              :image-source="this.getImageBack"
              :is-active="true"
              side="back"
              :flipped="false"
              :picture-height="667"
              :picture-width="500"
              :back-guide="getCropBack"
              :side-guide="getCropSide"
              :show-active-line="false"
            />
          </div>
          <div class="flex flex-col col-span-3 justify-center items-center">
            <p class="font-bold text-2xl pb-2">
              {{ $t("production_order_detail.side_view") }}
            </p>
            <bodyprint-view
              :image-source="this.getImageSide"
              :is-active="true"
              side="side"
              :flipped="false"
              :picture-height="667"
              :picture-width="500"
              :back-guide="getCropBack"
              :side-guide="getCropSide"
              :show-active-line="false"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="flex flex-col mx-auto p-4 w-full h-full items-center text-blue-800 text-2xl my-12"
      style="text-align: center"
    >
      {{ $t("production_order_detail.loading") }}
    </div>
    <Dialog
      v-if="showReturnDialog"
      @confirmed="toListScreen"
      @close="showReturnDialog = false"
      color="red"
      :title="$t('production_order_detail.dialogs.return.title').toString()"
      :message="$t('production_order_detail.dialogs.return.message').toString()"
    />
    <Dialog
      v-if="showSaveDialog"
      @confirmed="saveOrder"
      @close="showSaveDialog = false"
      :title="$t('production_order_detail.dialogs.save.title').toString()"
      :message="$t('production_order_detail.dialogs.save.message').toString()"
    />
    <Dialog
      v-if="showPdfDialog"
      @confirmed="exportToPdf"
      @close="showPdfDialog = false"
      color="yellow"
      :title="$t('production_order_detail.dialogs.export.title').toString()"
      :message="$t('production_order_detail.dialogs.export.message').toString()"
    />
    <Dialog
      v-if="showFinishDialog"
      @confirmed="finishOrder"
      @close="showFinishDialog = false"
      :title="$t('production_order_detail.dialogs.finish.title').toString()"
      :message="$t('production_order_detail.dialogs.finish.message').toString()"
    />
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :filename="`production-order-${getOrder?.order_number || 'unknown'}`"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <section class="pdf-item">
          <production-order-pdf-header :order-number="getOrder.order_number" />

          <div class="w-full px-6 pb-6 text-blue-800 bg-gray-200">
            <p class="font-bold text-2xl">
              {{ $t("production_order_detail.pdf.details") }}
            </p>
          </div>

          <div class="flex flex-col gap-4 mt-4">
            <div class="w-full px-6 text-blue-800 bg-gray-200">
              <production-order-detail
                v-if="getOrder"
                :order="getOrder"
                :is-rtw-tailoring="isRtwTailoring"
              />
            </div>
          </div>
        </section>

        <div class="html2pdf__page-break" />

        <section class="pdf-item">
          <production-order-pdf-header :order-number="getOrder.order_number" />

          <div class="w-full px-6 pb-6 text-blue-800 bg-gray-200">
            <p class="font-bold text-2xl">
              {{ $t("production_order_detail.pdf.measurements") }}
            </p>
          </div>

          <div class="w-full px-6">
            <production-order-body-table
              :adjustments="adjustments"
              :adjustments-loading="adjustmentsLoading"
              :adjustments-error="adjustmentsError"
              :is-pdf="true"
            />
            <production-order-garment-table
              :adjustments="adjustments"
              :fit-adjustments="fitAdjustments"
              :adjustments-loading="adjustmentsLoading"
              :adjustments-error="adjustmentsError"
              :is-rtw-tailoring="isRtwTailoring"
              :is-pdf="true"
            />
          </div>
        </section>

        <div class="html2pdf__page-break" />

        <section class="pdf-item">
          <production-order-pdf-header :order-number="getOrder.order_number" />

          <div class="w-full px-6 pb-2 text-blue-800 bg-gray-200">
            <p class="font-bold text-2xl">
              {{ $t("production_order_detail.pdf.images") }}
            </p>
          </div>

          <div
            class="flex flex-row justify-center items-center mx-auto text-blue-800 bg-gray-200"
            style="max-width: 100vw; padding: 0 0 2rem 0;"
          >
            <div class="flex flex-col items-center mr-4 ">
              <p class="font-bold text-2xl p-2 text-center mb-2">
                {{ $t("production_order_detail.back_view") }}
              </p>
              <bodyprint-view
                :image-source="this.getImageBack"
                :is-active="true"
                side="back"
                :flipped="false"
                :picture-height="558"
                :picture-width="418.5"
                :back-guide="getCropBack"
                :side-guide="getCropSide"
                :show-active-line="false"
              />
            </div>
            <div class="flex flex-col items-center ml-5 bg-gray-200">
              <p class="font-bold text-2xl p-2 text-center mb-2">
                {{ $t("production_order_detail.side_view") }}
              </p>
              <bodyprint-view
                :image-source="this.getImageSide"
                :is-active="true"
                side="side"
                :flipped="false"
                :picture-height="558"
                :picture-width="418.5"
                :back-guide="getCropBack"
                :side-guide="getCropSide"
                :show-active-line="false"
              />
            </div>
          </div>
        </section>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ROUTE_PRODUCTION } from "@/router";
import BodyprintView from "@/components/bodyprint/bodyprint-view.vue";
import Dialog from "@/components/dialog.vue";
import VueHtml2pdf from "vue-html2pdf";
import ProductionOrderGarmentTable from "@/components/production-order/production-order-garment-table.vue";
import ProductionOrderDetail from "@/components/production-order/production-order-detail.vue";
import ProductionOrderBodyTable from "@/components/production-order/production-order-body-table.vue";
import ProductionOrderPdfHeader from "@/components/production-order/pdf/production-order-pdf-header.vue";

export default {
  name: "ProductionOrderView",
  components: {
    ProductionOrderPdfHeader,
    ProductionOrderBodyTable,
    ProductionOrderGarmentTable,
    Dialog,
    BodyprintView,
    VueHtml2pdf,
    ProductionOrderDetail
  },
  created() {
    if (!this.getOrder) {
      this.getViewData();
      return;
    } else {
      this.prepareView();
    }

    window.addEventListener("resize", this.setImageWindowHeight);
    window.addEventListener("keydown", this.handleKeydown, null);
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {});
    window.removeEventListener("keydown", this.handleKeydown);
  },
  computed: {
    ...mapGetters({
      getAuthLoggedIn: "auth/getAuthLoggedIn",
      getAuth: "auth/getAuth",
      getSidebarOpen: "layout/getSidebarOpen",
      getOrder: "retailerOrders/getOrder",
      getImageSide: "retailerOrders/getImageSide",
      getImageBack: "retailerOrders/getImageBack",
      getPictureHeight: "retailerOrders/getPictureHeight",
      getPictureWidth: "retailerOrders/getPictureWidth",
      getCropBack: "retailerOrders/getCropBack",
      getCropSide: "retailerOrders/getCropSide",
      getClothingSizeItemDefaults: "configuration/getClothingSizeItemDefaults"
    }),
    isRtwTailoring() {
      return this.getOrder.tailoring === "RtW";
    }
  },
  data() {
    return {
      showSaveDialog: false,
      showReturnDialog: false,
      showPdfDialog: false,
      showFinishDialog: false,
      isLoading: true,
      adjustmentsError: "",
      adjustmentsLoading: true,
      adjustments: [],
      fitAdjustments: []
    };
  },
  methods: {
    ...mapMutations({
      setWindowHeight: "retailerOrders/setWindowHeight",
      setCrop: "retailerOrders/setCrop"
    }),
    ...mapActions(["auth/authCheck", "layout/toggleSidebar"]),
    getViewData() {
      this.$store
        .dispatch(
          "retailerOrders/requestOrderToView",
          this.$route.params.order_id
        )
        .then(() => {
          var order = this.$store.getters["retailerOrders/getOrder"];
          this.$store
            .dispatch("retailerOrders/getImages", order.id)
            .then(() => {
              this.$store
                .dispatch("retailerOrders/prepareOrderView")
                .then(() => {
                  this.prepareView();
                });
            })
            .catch(() => {
              this.$router.replace({ name: ROUTE_PRODUCTION });
            });
        });
    },
    prepareView() {
      if (!this.getOrder) return;

      this.$store
        .dispatch("configuration/getClothingSizeItemsDefaults")
        .then(() => {
          this.adjustments = [];
          this.fitAdjustments = [];

          this.isLoading = false;

          this.setImageWindowHeight();

          if (this.getOrder.style.size_item_templates.length === 0) {
            this.adjustmentsLoading = false;
          } else {
            for (const item of this.getOrder.style.size_item_templates) {
              let measurement = this.getOrder.queue_item.bodyprint_measurements.find(
                m => m.name === item.code
              );

              if (!measurement) {
                this.adjustmentsError = this.$t(
                  "production_order_detail.errors.incomplete_measurement_data"
                );
                this.adjustmentsLoading = false;
                break;
              }

              const getBodyMeasurementValue = measurement => {
                return measurement.value.toFixed(1);
              };

              let fitAdjustment = this.getOrder.fit_adjustments.find(
                f => f.size_item_code === item.code
              );

              if (!fitAdjustment) {
                this.adjustmentsError = this.$t(
                  "production_order_detail.errors.incomplete_fit_adjustment_data"
                );
                this.adjustmentsLoading = false;
                break;
              }

              if (!this.getOrder.style.fit_specification.specification_items) {
                this.adjustmentsError = this.$t(
                  "production_order_detail.errors.incomplete_fit_specification_data"
                );
                this.adjustmentsLoading = false;
                break;
              }

              let fitSpecificationItem = this.getOrder.style.fit_specification.specification_items[0].specs.find(
                m => m.code === item.code
              );

              if (!fitSpecificationItem) {
                this.adjustmentsError = this.$t(
                  "production_order_detail.errors.incomplete_fit_specification_data"
                );
                this.adjustmentsLoading = false;
                break;
              }

              const getGarmentMeasurementValue = measurement => {
                const value =
                  measurement.value + fitSpecificationItem.amount_of_space;

                const clothingSizeItem = this.getClothingSizeItemDefaults.find(
                  m => m.code === measurement.name
                );

                if (clothingSizeItem.type === "girth")
                  return (value / 2).toFixed(1);
                return value.toFixed(1);
              };

              let dropAdjustment = this.getOrder.style.drop_adjustments.find(
                m => m.code === item.code
              );

              const sizeItemLabel = this.$t(
                `production_order_detail.table.size_items.${item.code}`
              );

              let adjustment = {
                code: item.code,
                body_size_item: sizeItemLabel.replace("1/2", ""),
                size_item: sizeItemLabel,
                body_measurement: getBodyMeasurementValue(measurement),
                garment_measurement: getGarmentMeasurementValue(measurement),
                fit_specification: fitSpecificationItem.amount_of_space.toFixed(
                  1
                ),
                calculated_drop: dropAdjustment.value.toFixed(1)
              };

              if (this.isRtwTailoring) {
                let matchValue = this.getOrder.match.values.find(
                  m => m.code === item.code
                );

                adjustment = {
                  ...adjustment,
                  product_measurement: matchValue ? matchValue.base_size : 0
                };
              }

              this.adjustments.push(adjustment);

              this.fitAdjustments.push(fitAdjustment);
            }

            this.adjustmentsLoading = false;
          }
        })
        .catch(() => {
          this.adjustmentsError = this.$t(
            "production_order_detail.errors.incomplete_fit_specification_data"
          );
          this.adjustmentsLoading = false;
        });
    },
    setImageWindowHeight() {
      let payload = {
        height: window.innerHeight,
        width: window.innerWidth
      };
      this.setWindowHeight(payload);
      this.setProjectionCrop(
        this.getOrder.queue_item.bodyprint.guides[0].crop,
        "back"
      );
      this.setProjectionCrop(
        this.getOrder.queue_item.bodyprint.guides[1].crop,
        "left"
      );
    },
    setProjectionCrop(crop, projection) {
      let updatedCrop = {
        x: crop.x,
        x2: crop.x + crop.width,
        y: crop.y,
        y2: crop.y + crop.height,
        width: crop.width,
        height: crop.height
      };
      this.setCrop([updatedCrop, projection]);
    },
    toListScreen() {
      this.$router.replace({
        name: ROUTE_PRODUCTION
      });
      this.$store.dispatch("layout/toggleSidebar");
    },
    saveOrder() {
      const updatedFitAdjustments = [];
      this.fitAdjustments.forEach(item => {
        updatedFitAdjustments.push(item);
      });

      this.$store
        .dispatch("retailerOrders/updateFitAdjustments", {
          id: this.getOrder.id,
          adjustments: updatedFitAdjustments
        })
        .then(() => {
          this.toListScreen();
        });
    },
    async exportToPdf() {
      this.showPdfDialog = false;
      await this.$refs.html2Pdf.generatePdf().then(() => {
        this.$refs.html2Pdf.downloadPdf();
      });
    },
    finishOrder() {
      this.$store
        .dispatch("retailerOrders/updateOrderStatus", {
          id: this.getOrder.id,
          status: "IN_PRODUCTION"
        })
        .then(() => {
          this.showFinishDialog = false;
          this.getViewData();
        });
    }
  },
  watch: {}
};
</script>
